import { MdWbShade } from "react-icons/md";
import SunSmiley from "./SunSmiley";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

export default function SunlitNextHours(props) {
  const [shadeIcons, setShadeIcons] = useState();

  useEffect(() => {
    const now = DateTime.now()
      .plus({ minutes: 30 })
      .setLocale("se")
      .setZone("Europe/Stockholm")
      .set({ minutes: 0, seconds: 0, milliseconds: 0 });

    function getShadeInfo(inHowManyHours, shadowInfo) {
      const thisHour = now.plus({ hours: inHowManyHours });
      shadowInfo = shadowInfo ? shadowInfo : [];
      const result = shadowInfo.filter((shadowInfoHour) => {
        return DateTime.fromISO(shadowInfoHour.time).ts === thisHour.ts;
      });
      return result.length === 1
        ? result[0]
        : { time: 0, inShadow: { inShadow: true, sunset: true } };
    }

    const si = [];
    si[0] = getShadeInfo(0, props.restaurant.shadowInfo);
    si[1] = getShadeInfo(1, props.restaurant.shadowInfo);
    si[2] = getShadeInfo(2, props.restaurant.shadowInfo);
    si[3] = getShadeInfo(3, props.restaurant.shadowInfo);
    setShadeIcons(si.map(renderSunSmileyOrShade));
  }, [props]);

  function renderSunSmileyOrShade(shadeInfo) {    
    const isInShade = shadeInfo.inShadow.inShadow;
    return (
      <span>
        {isInShade ? (
          <span style={{ color: "black", verticalAlign: "middle" }}>
            <MdWbShade />
          </span>
        ) : (
          <span style={{ color: "orange" }}>
            <SunSmiley />
          </span>
        )}
      </span>
    );
  }

  return (
    <div>
      now
      {shadeIcons && shadeIcons[0]}
      <span style={{ marginLeft: "20px" }}>{"+1h"}</span>
      {shadeIcons && shadeIcons[1]}
      <span style={{ marginLeft: "20px" }}>{"+2h"}</span>
      {shadeIcons && shadeIcons[2]}
      <span style={{ marginLeft: "20px" }}>{"+3h"}</span>
      {shadeIcons && shadeIcons[3]}
    </div>
  );
}

import React from "react";
import RestaurantListItem from "./RestaurantListItem";
import StartUpScreen from "./StartUpScreen";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Toast, Switch } from "react-onsenui";

class Restaurants extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      numberOfFoundRestaurants: null,
      toastState: false,
      toastMessage: "",
      includeAll: false,
    };
  }

  componentDidMount() {
    if (this.props.coords) {
      this.findRestaurants(
        this.props.coords.latitude,
        this.props.coords.longitude,
        this.props.includeAll
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coords !== this.props.coords) {
      this.findRestaurants(
        this.props.coords.latitude,
        this.props.coords.longitude,
        this.props.includeAll
      );
    }
  }

  findRestaurants = (latitude, longitude, includeAll, resolve) => {
    const request = includeAll
      ? "/closestRestaurants/"
      : "/closestSunlitRestaurants/";
    const backendHost = process.env.REACT_APP_API_HOST;

    fetch("https://" + backendHost + request + longitude + "/" + latitude)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          data,
          numberOfFoundRestaurants: data.length,
          toastMessage: this.state.toastMessage,
          toastState: this.state.toastState,
        });
        if (resolve) resolve();
      });
  };

  closeToast = () => {
    let state = this.state;
    state.toastState = false;
    this.setState(state);
  };

  handleRefresh = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.findRestaurants(
            position.coords.latitude,
            position.coords.longitude,
            this.state.includeAll,
            resolve
          );
        },
        (err) => {
          let state = this.state;
          state.toastMessage =
            "Sorry, couldn't find our current location... 🌍 please allow the app to know where we are";
          state.toastState = true;
          this.setState(state);
          resolve();
        },
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 30000, // Use cached position within 30s
        }
      );
    });
  };

  showLoading = () => {
    return (
      <div>
        <span
          style={{
            fontFamily: "PT Sans",
            backgroundColor: "yellow",
            fontSize: "42pt",
            fontWeight: "700",
          }}
        >
          <br />
          <i>Lookin' for places that are sunlit.today</i> 😎
          <br />
        </span>
        <img
          alt="Loading indicator"
          src={"https://sunlit.today/sunloader.gif"}
        />
      </div>
    );
  };

  onIncludeAllClick = () => {
    let state = this.state;
    state.includeAll = !state.includeAll;
    this.setState(state);
    this.findRestaurants(
      this.props.coords.latitude,
      this.props.coords.longitude,
      this.state.includeAll
    );
  };

  render() {
    const restaurants = this.state.data.map((restaurant) => {
      return (
        <RestaurantListItem
          name={restaurant.tags.name}
          website={restaurant.tags.website}
          id={restaurant.id}
          key={restaurant.id}
          distance={restaurant.distance}
          longitude={restaurant.geometry[0]}
          latitude={restaurant.geometry[1]}
          nonGroundPosZ={restaurant.nonGroundPosZ}
          restaurant={restaurant}
        />
      );
    });

    return this.props.coords ? (
      <div style={{ width: "100%" }}>
        {!Number.isInteger(this.state.numberOfFoundRestaurants) ? (
          <StartUpScreen />
        ) : null}
        {this.state.numberOfFoundRestaurants === 0 ? (
          <div>
            <br />
            <span
              style={{
                fontFamily: "PT Sans",
                backgroundColor: "yellow",
                fontSize: "42pt",
                fontWeight: "700",
                fontStyle: "italic",
              }}
            >
              <br />
              Did not find any sunlit restaurants close by - sorry 🤷
            </span>
            <br />
          </div>
        ) : null}

        <PullToRefresh onRefresh={this.handleRefresh} pullingContent="">
          {Number.isInteger(this.state.numberOfFoundRestaurants) && (
            <span
              style={{
                fontFamily: "PT Sans",
                fontSize: "18pt",
                fontWeight: "700",
                fontStyle: "italic",
              }}
            >
              <br />
              <Switch
                checked={!this.state.includeAll}
                onChange={this.onIncludeAllClick}
              ></Switch>
              <span onClick={this.onIncludeAllClick} style={{ marginLeft: "15px" }}>
                Include only sunlit places
              </span>
              <br />
              <br />
            </span>
          )}

          {restaurants}
        </PullToRefresh>
        <Toast visible={this.state.toastState}>
          <div onClick={this.closeToast}>{this.state.toastMessage}</div>
        </Toast>
      </div>
    ) : (
      <StartUpScreen />
    );
  }
}

export default Restaurants;

import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, SVGOverlay } from "react-leaflet";
import ShadowCalc from "./ShadowCalc";
import { useInterval } from "./useInterval";
import { GeoJSONMerger } from "./polygonmerge/geojsonMerger";
import GeoJSONFeatureCanvasRenderer from "./GeoJSONFeatureCanvasRenderer";
import MapButton from "../MapButton";
import lottieJson from "./../73677-sun-laughing-emoji.json";
import Lottie from "react-lottie-player";

export default function ShowMap(props) {
  const [shadows, setShadows] = useState();
  const [buildings, setBuildings] = useState();
  const [animationPaused, setAnimationPaused] = useState(true);
  const [animationTime, setAnimationTime] = useState();
  const [sunSetRiseTimes, setSunSetRiseTimes] = useState();
  const [loadCounter, setLoadCounter] = useState(0);
  const backendHost = process.env.REACT_APP_API_HOST;
  const isAndroid = navigator.userAgent.indexOf("Android") != -1;
  const shadowsAnimationDelayInSeconds = isAndroid ? 0.3 : 0.1;

  const convertToSimplePolygon = (multiPolygonFeatures) => {
    // Convert MultiPolygons to "simple" Polygons since Leaflet can't handle overlapping MultiPolygons
    const features = multiPolygonFeatures.map((building) => {
      return building.geometry.coordinates.map((polygon) => {
        return {
          type: "Feature",
          properties: {},
          geometry: { type: "Polygon", coordinates: [polygon[0]] },
        };
      });
    });
    return features.flat();
  };

  const hasSunSet = (time) => {
    return sunSetRiseTimes && time >= sunSetRiseTimes.sunsetStart;
  };

  function onMapButtonClick() {
    setAnimationPaused(!animationPaused);
  }

  function updateShade(shadows) {
    const mergedShade = shadows.features.map(GeoJSONMerger);

    if (mergedShade && mergedShade.length > 0) {
      const polygonFeatures = convertToSimplePolygon(mergedShade);
      setShadows({
        type: "FeatureCollection",
        features: polygonFeatures,
      });
    }
  }

  const whenReady = () => {
    if (loadCounter > 0) {
      // For some reason the tile load event is fired twice...
      setAnimationPaused(false);
    }
    setLoadCounter(loadCounter + 1);
  };

  useEffect(() => {
    const date = new Date();
    setAnimationTime(date);

    const sc = new ShadowCalc(
      date,
      props.longitude,
      props.latitude,
      props.nonGroundPosZ
    );
    setSunSetRiseTimes(sc.getSunRiseAndSetTimes());

    fetch(
      "https://" +
        backendHost +
        "/getBuildings/" +
        props.longitude +
        "/" +
        props.latitude
    )
      .then((response) => response.json())
      .then((buildingData) => {
        setBuildings(buildingData);
        const shadows = sc.getShadowsAsGeoJSON(buildingData);
        updateShade(shadows);
      });
  }, []);

  useInterval(() => {
    if (shadows && !animationPaused) {
      const diffInMinutes = isAndroid ? 10 : 3;
      const newAnimationTime = new Date(
        animationTime.getTime() + diffInMinutes * 60000
      );
      const sc = new ShadowCalc(
        newAnimationTime,
        props.longitude,
        props.latitude,
        props.nonGroundPosZ
      );
      setAnimationTime(newAnimationTime);

      const shadows = sc.getShadowsAsGeoJSON(buildings);
      updateShade(shadows);
    }
  }, shadowsAnimationDelayInSeconds * 1000);

  const timeNow = !hasSunSet(animationTime)
    ? new Intl.DateTimeFormat("en-GB", {
        timeStyle: "short",
      }).format(animationTime)
    : new Intl.DateTimeFormat("en-GB", {
        timeStyle: "short",
      }).format(sunSetRiseTimes.sunset);
  return (
    <div>
      {!shadows ? (
        <Lottie
          loop
          animationData={lottieJson}
          play
          style={{
            width: "25%",            
            height: 350,
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      ) : null}
      {shadows ? (
        <div style={{ paddingTop: "10px" }}>
          <MapContainer
            center={[props.latitude, props.longitude]}
            zoom={17}
            scrollWheelZoom={false}
            dragging={false}
            whenReady={whenReady}
            style={{ height: "350px" }}
          >
            <TileLayer
              eventHandlers={{ load: () => whenReady() }}
              detectRetina={false}
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://d2cfuekaely8rg.cloudfront.net/{z}/{x}/{y}.png"
            />
            <Marker position={[props.latitude, props.longitude]}></Marker>
            <GeoJSONFeatureCanvasRenderer
              key={animationTime}
              features={shadows.features}
              darkFade={hasSunSet(animationTime)}
            />
            <SVGOverlay
              bounds={[
                [props.latitude + 0.002, props.longitude + 0.002],
                [props.latitude - 0.002, props.longitude - 0.002],
              ]}
            >
              <text
                x="42%"
                y="53%"
                stroke="gray"
                fontSize="x-large"
                fontFamily="PT Sans"
                fontWeight={700}
                fontStyle="italic"
              >
                {timeNow}
              </text>
              <text
                x="42.2%"
                y="53.2%"
                stroke="black"
                fontSize="x-large"
                fontFamily="PT Sans"
                fontWeight={700}
                fontStyle="italic"
              >
                {timeNow}
              </text>
            </SVGOverlay>
            <MapButton
              title={animationPaused ? "Play" : "Pause"}
              onClick={onMapButtonClick}
              position="bottomright"
            />
          </MapContainer>
        </div>
      ) : null}
    </div>
  );
}

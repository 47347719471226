import Lottie from "react-lottie-player";
import lottieJson from "./73677-sun-laughing-emoji.json";

export default function StartUpScreen() {
  return (
    <div>
      <br />
      <span
        style={{
          fontFamily: "PT Sans",
          backgroundColor: "yellow",
          fontSize: "42pt",
          fontWeight: "700",
        }}
      >
        <i>Lookin' for places that are sunlit.today...</i> 
        <br />
        <br />
        <Lottie
          loop
          animationData={lottieJson}
          play
          style={{ width: "50%", height: 150 , display:"block", marginLeft:"auto", marginRight:"auto"}}
        />
      </span>
    </div>
  );
}

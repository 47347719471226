import Proj4 from "proj4";
import SunCalc from "suncalc";
import classifyPoint from "robust-point-in-polygon";

Proj4.defs(
  "EPSG:3011",
  "+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);

export default class ShadowCalc {
  time = new Date();
  longitude = 0.0;
  latitude = 0.0;
  azimuth = 0.0;
  elevationAngle = 0.0;
  sunHasRisen = false;
  sunRiseSetTimes = null;
  nonGroundPosZ = null;

  constructor(time, longitude, latitude, nonGroundPosZ ) {
    this.time = time;
    this.longitude = longitude;
    this.latitude = latitude;
    this.nonGroundPosZ = nonGroundPosZ; // Optional Z Position for rooftops

    this.elevationAngle = this.toDegrees(
      SunCalc.getPosition(time, latitude, longitude).altitude
    );
    this.azimuth =
      this.toDegrees(SunCalc.getPosition(time, latitude, longitude).azimuth) +
      180;
    this.sunRiseSetTimes = SunCalc.getTimes(time, latitude, longitude);
    this.sunHasRisen =
      time > this.sunRiseSetTimes.sunrise &&
      time < new Date(this.sunRiseSetTimes.sunset.getTime() - 60000 * 10); // Shadows behave strangely around sunset
  }

  getSunRiseAndSetTimes() {
    return this.sunRiseSetTimes;
  }

  toDegrees(radians) {
    return radians * (180 / Math.PI);
  }

  toRadians(angle) {
    return angle * (Math.PI / 180);
  }

  calculateShadowVector(buildingHeight) {
    const L = buildingHeight / Math.tan(this.toRadians(this.elevationAngle));

    const direction = this.azimuth - 180;
    const x = L * Math.cos(this.toRadians(direction));
    const y = L * Math.sin(this.toRadians(direction));

    return { x: x, y: y, shadowLength: L };
  }

  calculateShadowSegments(polygonArray, buildingHeight) {
    const shadow = this.calculateShadowVector(buildingHeight);
    let shadowPolygons = [];
    for (let i = 0; i < polygonArray.length - 1; i++) {
      const p1 = polygonArray[i];
      const p2 = polygonArray[i + 1];
      const shadowP1 = { x: p1.x + shadow.x, y: p1.y + shadow.y };
      const shadowP2 = { x: p2.x + shadow.x, y: p2.y + shadow.y };
      const shadowPolygon = [];
      shadowPolygon.push(p1);
      shadowPolygon.push(p2);
      shadowPolygon.push(shadowP2);
      shadowPolygon.push(shadowP1);
      shadowPolygons.push(shadowPolygon);
    }
    return shadowPolygons;
  }

  processBuilding(building) {
    let buildingHeight = building.attributes.TAK_Z - ( this.nonGroundPosZ ? this.nonGroundPosZ : building.geometry[0].z );
    buildingHeight = buildingHeight < 0 ? 0.0 : buildingHeight;
    let processedBuilding = {};
    processedBuilding.attributes = building.attributes;
    processedBuilding.geometry = {};
    processedBuilding.geometry = building.geometry;
    processedBuilding.shadowSegments = this.calculateShadowSegments(
      building.geometry,
      buildingHeight
    );
    return processedBuilding;
  }

  isPointWithinBoundingBox(
    pointX,
    pointY,
    withinX1,
    withinY1,
    withinX2,
    withinY2
  ) {
    return (
      withinX1 <= pointX &&
      pointX <= withinX2 &&
      withinY1 <= pointY &&
      pointY <= withinY2
    );
  }

  convertToArrayPolygon(shadowPolygon) {
    return [
      [shadowPolygon[0].x, shadowPolygon[0].y],
      [shadowPolygon[1].x, shadowPolygon[1].y],
      [shadowPolygon[2].x, shadowPolygon[2].y],
      [shadowPolygon[3].x, shadowPolygon[3].y],
    ];
  }

  coordinateToSWEREF99_18_00(long, lat) {
    const coordinatesAsSWEREF99_18_00 = Proj4("EPSG:3011", [long, lat]);
    return {
      pointX: coordinatesAsSWEREF99_18_00[1],
      pointY: coordinatesAsSWEREF99_18_00[0],
    };
  }

  isPointInShadow(processedBuildings, long, lat) {
    const { pointX, pointY } = this.coordinateToSWEREF99_18_00(long, lat);

    var inShadow = false;
    var polygons = [];
    processedBuildings.forEach((building) => {
      building.shadowSegments.forEach((shadowPolygon) => {
        if (
          classifyPoint(this.convertToArrayPolygon(shadowPolygon), [
            pointX,
            pointY,
          ]) !== 1
        ) {
          inShadow = true;
          polygons.push(shadowPolygon);
        }
      });
    });
    return {
      inShadow: inShadow,
      polygons: polygons,
      pointX: pointX,
      pointY: pointY,
    };
  }

  filterBuildings(buildings, long, lat, offset) {
    const { pointX, pointY } = this.coordinateToSWEREF99_18_00(long, lat);

    const bbox = {
      x1: pointX - offset,
      y1: pointY - offset,
      x2: pointX + offset,
      y2: pointY + offset,
    };

    const filteredBuildings = buildings.filter((building) => {
      const filtered = building.geometry.filter((point) => {
        return this.isPointWithinBoundingBox(
          point.x,
          point.y,
          bbox.x1,
          bbox.y1,
          bbox.x2,
          bbox.y2
        );
      });
      return filtered.length > 0;
    });
    return filteredBuildings;
  }

  processFilteredBuildings(buildings, long, lat, offset) {
    const filteredBuildings = this.filterBuildings(
      buildings,
      long,
      lat,
      offset
    );
    return this.processBuildings(filteredBuildings);
  }

  processBuildings(buildingsAsJson) {
    const buildings = buildingsAsJson.map((building) =>
      this.processBuilding(building)
    );
    return this.sunHasRisen ? buildings : [];
  }

  getShadowsAsGeoJSON(buildings) {
    const areaRadius = 250;
    const processedBuildings = this.processFilteredBuildings(
      buildings,
      this.longitude,
      this.latitude,
      areaRadius
    );

    function turnShadowSegmentsToGeoJSONPolygonArray(shadowSegment) {
      var segments = shadowSegment.map((point) => {
        // Convert to WGS84
        return Proj4("EPSG:3011", "WGS84", [point.y, point.x]);
      });
      segments.push(segments[0]);
      return [segments];
    }

    const features = processedBuildings.map((building) => {
      const shadowPolygonArrays = building.shadowSegments.map(
        turnShadowSegmentsToGeoJSONPolygonArray
      );
      const multipolygonGeo = {
        type: "MultiPolygon",
        coordinates: shadowPolygonArrays,
      };
      return {
        type: "Feature",
        properties: {
          name: building.attributes.FASTIGHET,
        },
        geometry: multipolygonGeo,
      };
    });
    return { type: "FeatureCollection", features: features };
  }
}

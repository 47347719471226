import { useParams } from "react-router-dom";
import Restaurants from "./Restaurants";
import React, { useState, useEffect } from "react";

export default function Admin() {
  const { long, lat } = useParams();
  const [coords, setCoordinates] = useState();

  useEffect(() => {
    setCoordinates({ longitude: long ? long : 0.0, latitude: lat ? lat : 0.0 });
  }, [long,lat]);

  function onFindLocation() {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setCoordinates(pos.coords);
      },
      (error) => {
        alert(error);
        console.error(error);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }

  return (
    <div>
      <button onClick={onFindLocation}>Find location</button>

      <Restaurants
        coords={coords}
        includeAll
        isGeolocationAvailable
        isGeolocationEnabled
      />
    </div>
  );
}

import React from "react";
import { useGeolocated } from "react-geolocated";
import StartUpScreen from "./StartUpScreen";
import Restaurants from "./Restaurants";

export default function RestaurantsGeoLocation(props) {
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  return !isGeolocationAvailable ? (
    <span
      style={{
        fontFamily: "PT Sans",
        backgroundColor: "yellow",
        fontSize: "42pt",
        fontWeight: "700",
        fontStyle: "italic",
      }}
    >
      Your browser does not support Geolocation
    </span>
  ) : !isGeolocationEnabled ? (
    <div>
      <span
        style={{
          fontFamily: "PT Sans",
          backgroundColor: "yellow",
          fontSize: "42pt",
          fontWeight: "700",
        }}
      >
        <br />
        <i>Geolocation is not enabled - please, please, please</i>🙏{" "}
        <i>give sunlit.today permission</i> 🌍
      </span>
      <br />
      <br />
      <a href="/">
        <button class="button-74">Find sunny restaurants nearby!</button>
      </a>
      <br />
      <br />
      <span
        style={{
          fontFamily: "PT Sans",
          fontWeight: "700",
          fontStyle: "italic",
        }}
      >
        <a style={{ color: "black" }} href="/about">
          We help you find the closest restaurant with sunny outdoor seating -
          Find out more ➡
        </a>
      </span>
    </div>
  ) : coords ? (
    <div>
      <Restaurants
        coords={coords}
        isGeolocationAvailable={isGeolocationAvailable}
        isGeolocationEnabled={isGeolocationEnabled}
      />
    </div>
  ) : (
    <StartUpScreen />
  );
}

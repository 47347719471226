import { useMap } from "react-leaflet";
import Locate from "leaflet.locatecontrol";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import * as L from "leaflet";
import { useEffect } from "react";

const LocateControl = () => {
  const map = useMap();

  useEffect(() => {
    var lc = L.control
      .locate({
        position: "topleft",
        strings: {
          title: "Show me where I am",
        },
        drawCircle: true,
        drawMarker: true,
        showCompass: false
      })
      .addTo(map);
  }, []);
  return null;
};

export default LocateControl;

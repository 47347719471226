import { useMapEvents, useMap } from "react-leaflet";
import CanvasOverlay from "react-leaflet-canvas-overlay";
import Proj4 from "proj4";
import React, { useEffect } from "react";

Proj4.defs(
  "EPSG:3011",
  "+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);

export default function GeoJSONFeatureCanvasRenderer(props) {
  const canvas = document.createElement("canvas");
  canvas.style.width = "300px";
  canvas.style.height = "300px";

  function coordinateToSWEREF99_18_00(lat, long) {
    const coordinatesAsSWEREF99_18_00 = Proj4("EPSG:3011", [long, lat]);
    return {
      pointX: coordinatesAsSWEREF99_18_00[1],
      pointY: coordinatesAsSWEREF99_18_00[0],
    };
  }

  useEffect(() => {
    update();
  });

  const latLongToCanvasCoordinates = (lat, long) => {
    const zoomBounds = map.getBounds();
    const origin1 = coordinateToSWEREF99_18_00(
      zoomBounds._northEast.lat,
      zoomBounds._southWest.lng
    );
    const origin2 = coordinateToSWEREF99_18_00(
      zoomBounds._southWest.lat,
      zoomBounds._northEast.lng
    );

    const diffX = origin2.pointX - origin1.pointX;
    const diffY = origin2.pointY - origin1.pointY;

    const factorX = canvas.width / diffX;
    const factorY = canvas.height / diffY;

    const coordAsSWEREF99_18_00 = coordinateToSWEREF99_18_00(lat, long);

    return [
      factorX * (coordAsSWEREF99_18_00.pointX - origin1.pointX),
      factorY * (coordAsSWEREF99_18_00.pointY - origin1.pointY),
    ];
  };

  const map = useMap();

  function drawFilledPolygon(geoJSONfeature, ctx) {
    if (
      geoJSONfeature.geometry.coordinates &&
      geoJSONfeature.geometry.coordinates[0] &&
      geoJSONfeature.geometry.coordinates[0][0]
    ) {
      const coords = geoJSONfeature.geometry.coordinates[0].map((coord) =>
        latLongToCanvasCoordinates(coord[1], coord[0])
      );

      ctx.fillStyle = "#000000";
      ctx.beginPath();
      ctx.moveTo(coords[0][1], coords[0][0]);
      for (var i = 1; i < coords.length; i++) {
        ctx.lineTo(coords[i][1], coords[i][0]);
      }
      ctx.closePath();
      ctx.fill();
    }
  }

  function update() {
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.globalAlpha = 0.5;
    ctx.globalCompositeOperation = "xor";

    if (props.darkFade) {
      ctx.fillStyle = "#000000";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    !props.darkFade &&
      props.features &&
      props.features.forEach((feature) => drawFilledPolygon(feature, ctx));
  }

  useMapEvents({
    zoomend: () => {
      canvas.style = {};
      update();
    },
    zoomstart: () => {
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
  });

  return (
    <CanvasOverlay
      canvas={canvas}
      bounds={map.getBounds()}
      width={props.width ? props.width : 300}
      height={props.height ? props.height : 300}
      style={{ width: "300px", height: "300px" }}
    />
  );
}

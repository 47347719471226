import React, { Component } from "react";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import { Page, Tabbar, Tab } from "react-onsenui";
import ons from "onsenui/esm";
import SearchMap from "./SearchMap";
import RestaurantSearch from "./RestaurantSearch";
import RestaurantsGeoLocation from "./RestaurantsGeoLocation";
import About from "./About";

export default class MobileApp extends Component {
  constructor() {
    super();
    this.state = { index: 0 };
    if (ons.platform.isIPhoneX() && window.navigator.standalone) {
      // Utility function
      // Add empty attribute to the <html> element
      document.documentElement.setAttribute("onsflag-iphonex-portrait", "");
    }
  }

  componentDidMount() {}

  renderTabs() {
    return [
      {
        content: <Page1 key="t1" />,
        tab: <Tab label="Closest sunlit terraces" key="b1" icon="fa-sun" />,
      },
      {
        content: <Page2 key="t2" />,
        tab: <Tab label="Map" key="b2" icon="fa-map" />,
      },
      {
        content: <Page3 key="t3" />,
        tab: <Tab label="Search" key="b3" icon="fa-search" />,
      },
      {
        content: <Page4 key="t4" />,
        tab: <Tab label="About" key="b4" icon="fa-info" />,
      },
    ];
  }

  render() {
    return (
      <Tabbar
        index={this.state.index}
        onPreChange={(event) => {
          if (event.index !== this.state.index) {
            this.setState({ index: event.index });
          }
        }}
        renderTabs={() => this.renderTabs()}
      />
    );
  }
}

class Page1 extends Component {
  onInfoButtonPress = () => {
    document.location.href = "/about";
  };

  render() {
    return (
      <Page>
        <RestaurantsGeoLocation />
      </Page>
    );
  }
}

class Page2 extends Component {
  render() {
    return (
      <Page>
        <SearchMap />
      </Page>
    );
  }
}

class Page3 extends Component {
  render() {
    return (
      <Page>
        <RestaurantSearch />
      </Page>
    );
  }
}

class Page4 extends Component {
  render() {
    return <About />;
  }
}

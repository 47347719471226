import { useState, useEffect } from "react";

function StreetViewLink(props) {
  const [link, setLink] = useState();

  const backendHost = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    fetch("https://" + backendHost + "/streetViewURL/" + props.id)
      .then((response) => response.json())
      .then((data) => {
        setLink(data.streetViewUrl);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [props.id]);

  return (
    <div>
      {link && (
        <a
          style={{ color: "black", fontSize: "12px" }}
          href={link}
          target="_blank"
          rel="noreferrer noopener"
        >
          Show Street View
        </a>
      )}
      {!link && (
      <div style={{height:"19px",display:"block"}}></div>
      )}
    </div>
  );
}

export default StreetViewLink;

import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import { SearchInput, ProgressBar } from "react-onsenui";
import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import RestaurantListItem from "./RestaurantListItem";
import { FaTwitter } from "react-icons/fa";

export default function RestaurantSearch(props) {
  const [searchQuery, setSearchQuery] = useState();
  const [hits, setHits] = useState({ data: [], numberOfFoundRestaurants: 0 });
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(false);

  function findRestaurants(name) {
    const request = "/searchForRestaurantByName/";
    const backendHost = process.env.REACT_APP_API_HOST;

    if (name && name.length > 0) {
      setLoading(true);
      fetch("https://" + backendHost + request + name)
        .then((response) => response.json())
        .then((data) => {
          setHits({ data, numberOfFoundRestaurants: data.length });
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    findRestaurants(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const now = DateTime.now()
      .plus({ minutes: 30 })
      .setLocale("se")
      .setZone("Europe/Stockholm")
      .set({ minutes: 0, seconds: 0, milliseconds: 0 });

    function getShadeInfo(inHowManyHours, shadowInfo) {
      const thisHour = now.plus({ hours: inHowManyHours });
      shadowInfo = shadowInfo ? shadowInfo : [];
      const result = shadowInfo.filter((shadowInfoHour) => {
        return DateTime.fromISO(shadowInfoHour.time).ts === thisHour.ts;
      });
      return result.length === 1
        ? result[0]
        : { time: 0, inShadow: { inShadow: true, sunset: true } };
    }

    const restaurantsListItems = hits.data.map((restaurant) => {
      return (
        <RestaurantListItem
          name={restaurant.tags.name}
          website={restaurant.tags.website}
          id={restaurant.id}
          key={restaurant.id}
          inShadow={[
            getShadeInfo(0, restaurant.shadowInfo),
            getShadeInfo(1, restaurant.shadowInfo),
            getShadeInfo(2, restaurant.shadowInfo),
            getShadeInfo(3, restaurant.shadowInfo),
          ]}
          distance={restaurant.distance}
          longitude={restaurant.geometry[0]}
          latitude={restaurant.geometry[1]}
          nonGroundPosZ={restaurant.nonGroundPosZ}
          restaurant={restaurant}
        />
      );
    });
    setRestaurants(restaurantsListItems);
  }, [hits]);

  return (
    <div>
      {loading && <ProgressBar indeterminate />}
      <h3
        style={{
          fontFamily: "PT Sans",
          fontSize: "42pt",
          fontWeight: "700",
          fontStyle: "italic",
        }}
      >
        Search
      </h3>
      <SearchInput
        value={searchQuery}
        onChange={(event) => {
          setSearchQuery(event.target.value);
        }}
        placeholder="Restaurant or bar to search for"
        style={{ width: "90%", marginBottom: "30px" }}
      />
      {hits.numberOfFoundRestaurants === 0 && (
        <div
          style={{
            fontFamily: "PT Sans",
            fontSize: "12pt",
            fontWeight: "700",
            fontStyle: "italic",
            marginRight: "40px",
            marginLeft: "40px",
          }}
        >
          <br />
          <span>
            Currently you can only search for bars and restaurants in
            Stockholm🇸🇪, but follow us for updates{" "}
            <a href="https://twitter.com/SunlitToday">
              <FaTwitter />
            </a>{" "}
          </span>
        </div>
      )}
      {restaurants}
    </div>
  );
}

import { FaTwitter } from "react-icons/fa";

function Footer() {
  return (
    <div>
      <br/>
      <a style={{ color: "black" }} href="/about">About sunlit.today</a> |  <a href="https://twitter.com/SunlitToday"><FaTwitter /></a>
      <br />
      <br />
    </div>
  );
}

export default Footer;

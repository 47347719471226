import { Polygon } from "./gpc.js";
import * as turf from "@turf/turf";

function createPolygonsFromGeoJSONFeature(geojsonFeature) {
  const polygons = geojsonFeature.geometry.coordinates.map((polygon) => {
    const p = Polygon.fromVertices({
      bounds: [polygon[0]],
      holes: polygon.slice(1),
    });

    return p;
  });
  return polygons;
}
function pointListToGeoJSONCoordinates(pointList) {
  var coordinates = pointList.map((p) => {
    return [p.x, p.y];
  });
  coordinates.push(coordinates[0]);
  return coordinates;
}

function createGeoJSONfromPolygon(polygon) {
  var geojson = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "MultiPolygon",
      coordinates: [],
    },
  };

  if (!polygon.pointList) {
    // Handle polyList containing MultiPolygon and SimplePolygon

    const holePolygons = polygon.polyList.filter((p) => p._isHole);
    const nonHolePolygon = polygon.polyList.filter((p) => !p._isHole)[0];

    var coordinates = pointListToGeoJSONCoordinates(nonHolePolygon.pointList);

    const coordinatesForHoles = holePolygons.map((holePolygon) => {
      return pointListToGeoJSONCoordinates(holePolygon.polyList[0].pointList);
    });
    geojson.geometry.coordinates = [[coordinates, ...coordinatesForHoles]];
  } else {
    var coordinates = pointListToGeoJSONCoordinates(polygon.pointList);
    geojson.geometry.coordinates = [[coordinates]];
  }

  return geojson;
}

function createGeoJSONfromRingsAndHoles(ringAndHoles) {
  var geojson = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "MultiPolygon",
      coordinates: [],
    },
  };

  const holePolygons = ringAndHoles.slice(1);
  const nonHolePolygon = ringAndHoles[0];

  var coordinates = pointListToGeoJSONCoordinates(nonHolePolygon.pointList);

  const coordinatesForHoles = holePolygons.map((holePolygon) => {
    return pointListToGeoJSONCoordinates(holePolygon.polyList[0].pointList);
  });
  geojson.geometry.coordinates = [[coordinates, ...coordinatesForHoles]];

  return geojson;
}

function associateHolesWithCorrectBounds(polyList) {
  const allHoles = polyList.filter((p) => p._isHole);
  const allBounds = polyList.filter((p) => !p._isHole);

  const allBoundsAsTurf = allBounds.map((boundSimplePolygon) => {
    const coordinates = boundSimplePolygon.pointList.map((p) => [p.x, p.y]);
    coordinates.push(coordinates[0]);
    return {
      srcPolygon: boundSimplePolygon,
      turfPolygon: turf.polygon([coordinates]),
    };
  });

  const allHolesAsTurf = allHoles.map((hole) => {
    const coordinates = hole.polyList[0].pointList.map((p) => [p.x, p.y]);
    coordinates.push(coordinates[0]);
    if (hole.polyList.length > 1)
      console.error("Unsupported: Multiple SimplePolygons in MultiPolygon");
    return { srcHole: hole, turfPolygon: turf.polygon([coordinates]) };
  });

  const boundsContainingHoles = allBoundsAsTurf.map((bound) => {
    const containingHoles = allHolesAsTurf.filter((hole) => {
      return turf.booleanContains(bound.turfPolygon, hole.turfPolygon);
    });
    const holes = containingHoles.map((hole) => hole.srcHole);
    return [bound.srcPolygon, ...holes];
  });
  return boundsContainingHoles;
}

export function GeoJSONMerger(geojson) {
  const polys = createPolygonsFromGeoJSONFeature(geojson);
  const mergedPoly = Polygon.union(...polys);
  return createGeoJSONfromPolygon(mergedPoly);
}

export function CompleteGEOJsonMerger(geojsonFeatures) {
  const allPolygons = geojsonFeatures
    .map(createPolygonsFromGeoJSONFeature)
    .flat();

  const mergedPoly = Polygon.union(...allPolygons);
  const polyList = mergedPoly.polyList ? mergedPoly.polyList : [];

  const ringsAndHoles = associateHolesWithCorrectBounds(polyList);

  const features = ringsAndHoles.map(createGeoJSONfromRingsAndHoles);

  const featureCollection = {
    type: "FeatureCollection",
    features: features,
  };
  console.log(featureCollection);
  return featureCollection;
}

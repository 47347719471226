import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SunlitView from "./SunlitView";
import About from "./About";
import Admin from "./Admin";
import Location from "./Location";
import SearchMap from "./SearchMap";
import MobileApp from "./MobileApp";

function App() {  
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MobileApp />} />
          <Route path="/about" element={<About />} />
          <Route path="/mobile" element={<MobileApp/>}/>
          <Route path="/at/:long/:lat" element={<Location />} />
          <Route path="/all/:long/:lat" element={<Admin />} />
          <Route path="/all" element={<Admin />} />
          <Route path="/map" element={<SearchMap />} />
          <Route path="*" element={<About/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

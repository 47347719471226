import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  useMap,
  Popup,
} from "react-leaflet";
import { useState, useEffect } from "react";
import L from "leaflet";
import SunlitNextHours from "./SunlitNextHours";
import LocateControl from "./LocateControl";

export default function SearchMap(props) {
  const [hits, setHits] = useState();
  const longitude = 18.070635438691983;
  const latitude = 59.325355288520306;
  const [geoLocated, setGeoLocated] = useState(false);

  const myIcon = new L.divIcon({
    className: "",
    iconAnchor: [12, 25],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -15],
    iconSize: [25, 41],
    html: `<span style="font-size:40px">😎</span>`,
  });

  function LocationMarker(props) {
    const map = useMap();
    let circle = null;
    let innerCircle = null;

    useEffect(() => {
      if (!props.geoLocated) {
        setGeoLocated(true);

        map
          .locate({ setView: true, watch: false, maxZoom: 17 })
          .on("locationfound", function (e) {
            findRestaurants(e.latlng.lat, e.latlng.lng, false);
          });
      }
    }, [map]);

    return null;
  }

  function restaurantToMarker(restaurant) {
    return (
      <Marker
        key={restaurant.id}
        position={[restaurant.geometry[1], restaurant.geometry[0]]}
        icon={myIcon}
      >
        <Popup minWidth={225}>
          <span
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <a
              style={{
                fontFamily: "PT Sans",
                fontWeight: "700",
                fontStyle: "italic",
                color: "black",
                fontSize: "24px",
              }}
              href={restaurant.tags.website}
              target="_blank"
              rel="noreferrer noopener"
            >
              <b>{restaurant.tags.name}</b>
            </a>
          </span>
          <SunlitNextHours restaurant={restaurant} />
        </Popup>
      </Marker>
    );
  }

  const findRestaurants = (latitude, longitude, includeAll) => {
    const request = includeAll
      ? "/closestRestaurants/"
      : "/closestSunlitRestaurants/";
    const backendHost = process.env.REACT_APP_API_HOST;

    fetch("https://" + backendHost + request + longitude + "/" + latitude)
      .then((response) => response.json())
      .then((data) => setHits(data.map(restaurantToMarker)));
  };

  function DragEnd() {
    const map = useMap();
    useMapEvents({
      dragend(e) {
        const longlat = e.target.getCenter();
        findRestaurants(longlat.lat, longlat.lng, false);
      },
      zoomend(e) {
        const longlat = e.target.getCenter();
        findRestaurants(longlat.lat, longlat.lng, false);        
      }
    });
    return null;
  }
  //https://maps.omniscale.net/v2/sunlit-today-eb957324/style.default/{z}/{x}/{y}.png
  return (
    <div style={{ height: "100%", overflow: "clip", display: "block" }}>
      <MapContainer scrollWheelZoom={false} dragging={true}>
        <TileLayer
          detectRetina={false}
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://d2cfuekaely8rg.cloudfront.net/{z}/{x}/{y}.png"
        />
        <DragEnd />
        {hits}
        <LocationMarker geoLocated={geoLocated} />
        <LocateControl/>
      </MapContainer>
    </div>
  );
}

import logo from "./sun-smiley.svg";

function SunSmiley() {
    const isWindows = navigator.userAgent.indexOf("Windows") != -1;
    const smiley = isWindows ? <img src={logo} alt="logo" width={36} /> : "😎"

    return smiley;
}

export default SunSmiley;
